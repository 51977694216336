import * as React from "react"
import { ThemeProvider } from "@mui/material"
import { CssBaseline } from "@mui/material"

import TopBar from "../components/TopBar"
import Footer from "../components/Footer"
import lightTheme from "../themes/light"
import HomeTextContainer from "../components/HomeTextContainer"
import SmallHero from "../components/SmallHero"

import { graphql } from "gatsby"

const VdsPage = ({data}) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <title>ITS - Chi siamo</title>
      <CssBaseline />
      <TopBar  />
      <SmallHero 
        title="Videosorveglianza"
        description="Impianti professionali di videosorveglianza, sia cablata che via radio.        "
        href="servizi"
        image="https://scontent.fpsa1-1.fna.fbcdn.net/v/t1.18169-9/189873_144713605594854_8348389_n.jpg?_nc_cat=103&ccb=1-5&_nc_sid=cdbe9c&_nc_ohc=BnaiHEtUnDMAX9ZGfs0&tn=2MVHObMWMXOdbZs6&_nc_ht=scontent.fpsa1-1.fna&oh=fa22cccd82260e335cf0c5ebd1ca07f3&oe=61A6FEE1"
        hideButton={true}
        />

        <HomeTextContainer 
        title="ITS Telecomunicazioni //" 
        subtitle={data.markdownRemark.frontmatter.title} 
        hideIcon={true}
        hideButton={true}
        description={ data.markdownRemark.html }
        noBorder/>

      <Footer />
    </ThemeProvider>
  )
}

export default VdsPage

export const contentQuery = graphql`
{
  markdownRemark(fileAbsolutePath: { regex: "/vds.md/"}) {
    frontmatter {
			title
    }
    html
  }
}
`